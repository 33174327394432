@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");

body {
  margin: 0;
  font-family: "Audiowide", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #18181b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
.retro-glow {
  font-family: "Audiowide", sans-serif;
}

@layer components {
  .retro-container {
    position: relative;
    overflow: hidden;
    animation: fadeIn 1s ease-in-out;
    background: linear-gradient(135deg, #ff2d55, #0066ff);
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }

  .retro-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.05) 50%);
    background-size: 100% 4px;
    animation: scanline 8s linear infinite;
    pointer-events: none;
  }

  .retro-glow {
    color: #ff2d55;
    text-shadow: 0 0 10px #ff2d55, 0 0 20px #ff2d55, 0 0 30px #0066ff;
    transition: text-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .retro-glow:hover {
    text-shadow: 0 0 20px #ff2d55, 0 0 30px #0066ff, 0 0 40px #ff2d55;
    transform: scale(1.05);
  }

  .retro-border {
    border: 2px solid #ff2d55;
    box-shadow: 0 0 10px #ff2d55, inset 0 0 10px #ff2d55;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .retro-border:hover {
    box-shadow: 0 0 20px #ff2d55, inset 0 0 20px #ff2d55;
    transform: rotate(2deg);
  }

  .gradient-text {
    background: linear-gradient(45deg, #ff2d55, #0066ff);
    -webkit-background-clip: text;
    color: transparent;
    transition: background 0.5s ease-in-out;
  }

  .gradient-text:hover {
    background: linear-gradient(45deg, #0066ff, #ff2d55);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media (max-width: 768px) {
  .retro-container {
    padding: 1rem;
    border-radius: 10px;
  }

  .text-center {
    text-align: center;
  }

  .text-6xl {
    font-size: 2.5rem;
  }

  .text-3xl {
    font-size: 1.75rem;
  }

  .text-2xl {
    font-size: 1.5rem;
  }

  .space-y-6 > * + * {
    margin-top: 1.5rem;
  }

  .grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
